<template>
  <div>
    <!-- grid layout -->
    <ProductListingItemGrid
      :itemDetailPageId="productDetailPageId"
      :itemId="product.id"
      :itemSKU="product.sku"
      :display="display"
      :soldOutAppearance="soldOutAppearance"
      :imageUrl="imageUrl"
      :imageId="imageId"
      :imageKindId="shopId"
      :imageGrayscale="imageGrayscale"
      :imageRatio="previewImageRatio"
      :itemTitle="productTitle"
      :priceLabel="priceLabel"
      :productKind="product.config.productKind"
      :productSubKind="product.config.productSubKind"
      v-if="layout == 'grid'"
    />

    <!-- list layout -->
    <ProductListingItemList
      :itemDetailDisplay="productDetailDisplay"
      :itemDetailPageId="productDetailPageId"
      :itemSKU="product.sku"
      :display="display"
      :soldOutAppearance="soldOutAppearance"
      :imageUrl="imageUrl"
      :imageId="imageId"
      :imageKindId="shopId"
      :imageGrayscale="imageGrayscale"
      :imageRatio="previewImageRatio"
      :item="product"
      :itemTitle="productTitle"
      :itemDesc="productDesc"
      :priceLabel="priceLabel"
      :productKind="product.config.productKind"
      :productSubKind="product.config.productSubKind"
      v-if="layout == 'list'"
    />
  </div>
</template>

<script>
import maxBy from "lodash/maxBy"
import minBy from "lodash/minBy"
import Utils from "@/utils"
import ProductListingItemGrid from "./ProductListingItemGrid.vue"
import ProductListingItemList from "./ProductListingItemList.vue"
import {useShopCartStore} from "@/stores/shopCart"
import {useShopItemsStore} from "@/stores/shopItems"
import {useSiteStore} from "@/stores/site"
import {storeToRefs} from "pinia"

export default {
  name: "ProductListingItem",

  props: {
    product: {
      type: Object,
      default: () => {
        return {
          config: {
            images: [
              {
                name: "bouquet.jpg",
              },
            ],
          },
          desc: {
            title: {
              cs: "produkt",
            },
          },
          price: [
            {
              brutto: 1234,
            },
          ],
          sku: "lala",
          tags: [
            {
              en: "kytice",
            },
          ],
        }
      },
    },
    block: {
      type: Object,
    },
    previewImageRatio: {
      type: String,
      default: "square",
    },
    layout: {
      type: String,
      default: "grid",
    },
  },

  components: {
    ProductListingItemGrid,
    ProductListingItemList,
  },
  setup() {
    // stores
    const shopCartStore = useShopCartStore()
    const shopItemsStore = useShopItemsStore()
    const siteStore = useSiteStore()

    // states
    const {frontend: shopCartFrontend} = storeToRefs(shopCartStore)
    const {siteVar} = storeToRefs(siteStore)

    // getters
    const {siteLangCurrent} = storeToRefs(siteStore)

    // actions
    const {getItemVariants: shopItemsGetItemVariants} = shopItemsStore

    return {
      siteVar,
      siteLangCurrent,
      shopCartFrontend,
      shopItemsGetItemVariants,
      shopItemsStore,
    }
  },
  computed: {
    currentLang() {
      return this.siteLangCurrent
    },
    priceLabel() {
      // console.log('SOA:', this.soldOutAppearance)
      if (
        this.display == "unavailable" &&
        this.soldOutAppearance &&
        this.soldOutAppearance.price == "label"
      ) {
        return "vyprodáno"
      } else if (
        this.display != "unavailable" ||
        (this.soldOutAppearance && this.soldOutAppearance.price == "price")
      ) {
        return `${this.price}`
      }
      return null
    },
    productTitle() {
      return this.product.desc &&
        this.product.desc.title &&
        this.product.desc.title[this.currentLang]
        ? this.product.desc.title[this.currentLang]
        : "-"
    },
    productDesc() {
      return this.product.desc &&
        this.product.desc.description &&
        this.product.desc.description[this.currentLang]
        ? this.product.desc.description[this.currentLang]
        : ""
    },
    productDetailDisplay() {
      if (!this.block.config || !this.block.config.productDetailDisplay) return
      return this.block.config.productDetailDisplay
    },
    productDetailPageId() {
      if (
        (!this.block.config || !this.block.config.productDetailPageId) &&
        (!this.product.config || !this.product.config.itemDetailPageId)
      ) {
        return "default"
      }
      // if product has detail page override
      return this.product.config.itemDetailPageId
        ? this.product.config.itemDetailPageId
        : this.block.config.productDetailPageId
    },
    soldOutAppearance() {
      if (!this.siteVar.globalOptions) return
      return this.siteVar.globalOptions.sections.commerce.productListingItem.appearance.soldOut
    },
    display() {
      let display = false
      if (this.hasVariants) {
        this.productVariants.forEach((variant) => {
          let vd = variant._display
          // only set if display is still false
          if (vd == "available") display = "available"
          if (vd == "unavailable" && display != "available") display = "unavailable"
        })
      }

      if (display != "available") {
        display = this.product._display
      }
      return display
    },
    mainImage() {
      let mainImage = this.product.config.images?.find((i) => i.main)
      if (mainImage) {
        return mainImage
      } else {
        let firstFoundVariantImage = this.product.config.images?.find((i) => i.variant)
        return firstFoundVariantImage
      }
    },
    shopId() {
      return this.siteVar.shopId
    },
    imageId() {
      if (!this.mainImage) return false
      return this.mainImage && this.mainImage.imageId ? this.mainImage.imageId : this.mainImage.name // legacy
    },
    imageUrl() {
      if (!this.product.config.images || !this.product.config.images[0]) return false

      let shopId = this.shopId
      let image = this.mainImage
      let imageId = image.imageId

      let params = "$w_320,q_80,x_3" // default is jpeg
      if (image.contentType == "image/svg+xml") params = ""
      if (image.contentType == "image/jpeg") params = "$w_320,q_80,x_3"
      if (image.contentType == "image/png") params = "$w_320,c_9,x_3"

      let imageUrl = `${import.meta.env.VITE_URL_STATIC}/shops/${shopId}/images/${imageId}${params}`
      // console.log('imageUrl():', this.selectedVariant.config.images, mainImage, variantImage, imageShown, imageId)
      return imageUrl
    },
    imageGrayscale() {
      if (
        this.display == "unavailable" &&
        this.soldOutAppearance &&
        this.soldOutAppearance.image == "grayscale"
      ) {
        return true
      } else {
        return false
      }
    },
    hasVariants() {
      if (!this.product.variants) return false
      if (this.product.variants.length > 0) return true
      return false
    },
    price() {
      let prices = []
      let currency = this.shopCartFrontend.currency
      let mainPriceKind = this.product.config.priceKind
      let productPrices = []

      // if this is a product without variants with voluntary price, just return its min/max
      if (mainPriceKind === "voluntary" && !this.hasVariants) {
        const productPrice = this.product.price.find(
          (p) => p.currency == currency && p.model == mainPriceKind
        )
        if (!productPrice) return
        return `${Utils.formatNumber(productPrice.min)} - ${Utils.formatNumber(productPrice.max)}`
      }

      this.product.price.forEach((price) => {
        prices.push(price)
      })

      if (this.productVariants) {
        this.productVariants.forEach((variant) => {
          variant.price.forEach((price) => {
            prices.push(price)
          })
        })
      }

      productPrices = prices.filter((p) => p.currency == currency && p.model == mainPriceKind)

      if (productPrices.length === 0) return

      if (mainPriceKind == "package") {
        let min = minBy(productPrices, (o) => parseFloat(o.bruttoPerPackage))
        let max = maxBy(productPrices, (o) => parseFloat(o.bruttoPerPackage))
        if (min && max && min.bruttoPerPackage == max.bruttoPerPackage)
          return Utils.formatNumber(min.bruttoPerPackage)
        else
          return `${Utils.formatNumber(min.bruttoPerPackage)} - ${Utils.formatNumber(
            max.bruttoPerPackage
          )}`
      } else if (mainPriceKind == "voluntary") {
        let min = minBy(productPrices, (o) => parseFloat(o.min))
        let max = maxBy(productPrices, (o) => parseFloat(o.max))
        if (min && max && min.min == max.min) return Utils.formatNumber(min.min)
        if (!min || !max) return `${min} - ${max}`
        else return `${Utils.formatNumber(min.min)} - ${Utils.formatNumber(max.max)}`
      } else {
        let min = minBy(productPrices, (o) => parseFloat(o.brutto))
        let max = maxBy(productPrices, (o) => parseFloat(o.brutto))
        if (min && max && min.brutto == max.brutto) return Utils.formatNumber(min.brutto)
        else return `${Utils.formatNumber(min.brutto)} - ${Utils.formatNumber(max.brutto)}`
      }
    },
    productVariants() {
      // let pv = this.shopItemsGetItemVariants(this.product.id)
      let pv = this.shopItemsStore.getItemVariants(this.product.id) // destructured action throws errors
      // console.log('pv:', pv)
      return pv
    },
  },
}
</script>

<style scoped lang="less"></style>
